import React from 'react';
import { Accordion, Button } from 'flowbite-react';
import { FaComments } from 'react-icons/fa';

const FAQ = () => (
    <section id="faq" className="px-4 sm:px-8 py-16 bg-gray-10">
        <div className="container mx-auto text-center">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Frequently Asked Questions</h2>
            <p className="text-gray-600 max-w-2xl mx-auto mb-8">
                Here are some common questions about our eSIM solution. Feel free to reach out if you need more information.
            </p>

            {/* Centered FAQ items in a single column with reduced spacing */}
            <div className="max-w-2xl mx-auto">
                <Accordion flush>
                    <Accordion.Panel>
                        <Accordion.Title>What is an eSIM and how does it work?</Accordion.Title>
                        <Accordion.Content>
                            <p className="text-gray-700">
                                An eSIM is a digital SIM that allows you to activate a cellular plan without a physical SIM card. You can use it to stay connected in various countries.
                            </p>
                        </Accordion.Content>
                    </Accordion.Panel>

                    <Accordion.Panel>
                        <Accordion.Title>Can I use the eSIM on multiple devices?</Accordion.Title>
                        <Accordion.Content>
                            <p className="text-gray-700">
                                No, an eSIM is typically limited to a single device once activated. You would need a new eSIM for a different device.
                            </p>
                        </Accordion.Content>
                    </Accordion.Panel>

                    <Accordion.Panel>
                        <Accordion.Title>Are there any fees to activate the eSIM?</Accordion.Title>
                        <Accordion.Content>
                            <p className="text-gray-700">
                                There is no additional fee to activate the eSIM, but data charges may apply based on your selected plan.
                            </p>
                        </Accordion.Content>
                    </Accordion.Panel>

                    <Accordion.Panel>
                        <Accordion.Title>Is my data secure with an eSIM?</Accordion.Title>
                        <Accordion.Content>
                            <p className="text-gray-700">
                                Yes, eSIM technology follows strict security standards and protects your data just like a physical SIM card.
                            </p>
                        </Accordion.Content>
                    </Accordion.Panel>

                    <Accordion.Panel>
                        <Accordion.Title>How is my personal information protected?</Accordion.Title>
                        <Accordion.Content>
                            <p className="text-gray-700">
                                We follow industry-standard practices to protect your information and ensure your data is safe and secure.
                            </p>
                        </Accordion.Content>
                    </Accordion.Panel>

                    <Accordion.Panel>
                        <Accordion.Title>How can I contact support?</Accordion.Title>
                        <Accordion.Content>
                            <p className="text-gray-700">
                                You can reach our support team 24/7 through the app or our website's contact form. We're here to help!
                            </p>
                        </Accordion.Content>
                    </Accordion.Panel>

                    <Accordion.Panel>
                        <Accordion.Title>What should I do if I encounter issues with my eSIM?</Accordion.Title>
                        <Accordion.Content>
                            <p className="text-gray-700">
                                If you face any issues, please contact support. Our team is ready to assist you with any questions or concerns.
                            </p>
                        </Accordion.Content>
                    </Accordion.Panel>

                    <Accordion.Panel>
                        <Accordion.Title>Do you offer refunds for unused data?</Accordion.Title>
                        <Accordion.Content>
                            <p className="text-gray-700">
                                Yes, refunds are available under certain conditions. Please check our refund policy or contact support for more information.
                            </p>
                        </Accordion.Content>
                    </Accordion.Panel>
                </Accordion>
            </div>
            {/* Chat Button Section */}
            <div id="contact-us" className="pt-20 pb-10">
                <p className="text-gray-700 mb-4 font-semibold">
                    Didn’t find the answer you were looking for?
                </p>
                <Button
                    color="blue"
                    pill={true}
                    size="lg"
                    className="flex items-center justify-center mx-auto transition-transform transform hover:scale-105 hover:bg-purple-700"
                    onClick={() => window.open("https://example.com/chat", "_blank")}
                >
                    <FaComments className="mr-2 text-lg" />
                    Chat with Us
                </Button>
                <p className="text-gray-600 mt-4 text-xs">
                    Our support team is available 24/7 to assist you with any questions you might have.
                </p>
            </div>
        </div>
    </section>
);

export default FAQ;
