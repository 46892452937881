import React from 'react';

const RefundPolicy = () => {
  return (
    <section className="px-6 py-16 bg-gray-50 text-gray-800">
      <div className="container mx-auto max-w-4xl">
        <h1 className="text-4xl font-bold mb-6 text-center">Refund Policy</h1>

        {/* Table of Contents */}
        <div className="bg-white shadow-md rounded-lg p-6 mb-12">
          <h2 className="text-2xl font-semibold mb-4">Table of Contents</h2>
          <ul className="list-disc pl-5 space-y-2 text-gray-700">
            <li><a href="#eligibility" className="hover:underline">Eligibility for Refunds</a></li>
            <li><a href="#refund-request-process" className="hover:underline">Refund Request Process</a></li>
            <li><a href="#non-refundable-cases" className="hover:underline">Non-Refundable Cases</a></li>
            <li><a href="#refund-timeline" className="hover:underline">Refund Timeline</a></li>
            <li><a href="#partial-refunds" className="hover:underline">Partial Refunds</a></li>
            <li><a href="#contact-us" className="hover:underline">Contact Us</a></li>
          </ul>
        </div>

        {/* Section: Eligibility for Refunds */}
        <section id="eligibility" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Eligibility for Refunds</h2>
          <p className="leading-relaxed text-gray-600">
            Refunds are granted under specific conditions. You may be eligible for a refund if you experience service disruptions due to errors on our end or issues beyond your control. Please note that refund eligibility may vary depending on the nature of the product or service purchased.
          </p>
          <ul className="list-disc pl-5 mt-4 space-y-2 text-gray-600">
            <li>Services not received due to technical issues caused by our system.</li>
            <li>Incorrect billing or duplicate charges on your account.</li>
            <li>Refund requests made within 14 days of the original purchase, unless otherwise stated.</li>
          </ul>
          <p className="leading-relaxed text-gray-600 mt-4">
            All refund requests are subject to review, and we reserve the right to deny a refund if the request does not meet the eligibility criteria.
          </p>
        </section>

        {/* Section: Refund Request Process */}
        <section id="refund-request-process" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Refund Request Process</h2>
          <p className="leading-relaxed text-gray-600">
            To request a refund, you must contact our support team and provide details regarding your issue. This includes your account information, the reason for your refund request, and any relevant transaction details. Our team will review your request and notify you of the status within a reasonable timeframe.
          </p>
          <ol className="list-decimal pl-5 mt-4 space-y-2 text-gray-600">
            <li>Contact support via email at <a href="mailto:support@roamerz.com" className="text-blue-600 hover:underline">support@example.com</a>.</li>
            <li>Include the transaction ID, date of purchase, and reason for the refund request.</li>
            <li>Our team will verify your request and respond within 3-5 business days.</li>
          </ol>
          <p className="leading-relaxed text-gray-600 mt-4">
            Please note that we may request additional information to verify your eligibility for a refund.
          </p>
        </section>

        {/* Section: Non-Refundable Cases */}
        <section id="non-refundable-cases" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Non-Refundable Cases</h2>
          <p className="leading-relaxed text-gray-600">
            Certain products and services are non-refundable. This includes instances where the service was rendered in full or where you have used a significant portion of the service. Additionally, refund requests will not be honored under the following circumstances:
          </p>
          <ul className="list-disc pl-5 mt-4 space-y-2 text-gray-600">
            <li>Refund requests made after 14 days of purchase.</li>
            <li>Services rendered or substantially used before the refund request.</li>
            <li>Purchases made through third-party platforms; please contact the original provider for such refunds.</li>
            <li>Promotional or discounted purchases, which are typically non-refundable.</li>
          </ul>
          <p className="leading-relaxed text-gray-600 mt-4">
            We advise all customers to review their purchase carefully and ensure it meets their needs before completing the transaction.
          </p>
        </section>

        {/* Section: Refund Timeline */}
        <section id="refund-timeline" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Refund Timeline</h2>
          <p className="leading-relaxed text-gray-600">
            Refunds are processed as quickly as possible once approved. Please note that processing times may vary depending on your payment provider. Refunds typically take between 5-10 business days to appear in your account, though delays may occur based on your financial institution's policies.
          </p>
          <p className="leading-relaxed text-gray-600 mt-4">
            We will send a confirmation email once your refund has been issued. If you do not see the refund in your account after the expected timeframe, please reach out to your bank or payment provider.
          </p>
        </section>

        {/* Section: Partial Refunds */}
        <section id="partial-refunds" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Partial Refunds</h2>
          <p className="leading-relaxed text-gray-600">
            In certain cases, a partial refund may be issued if you have used part of the service. The amount refunded will be proportional to the unused portion of the service. Partial refunds are granted at our discretion and are subject to our review process.
          </p>
          <ul className="list-disc pl-5 mt-4 space-y-2 text-gray-600">
            <li>Partial refunds are only available under specific circumstances.</li>
            <li>Contact support for more information if you believe a partial refund applies to your case.</li>
          </ul>
        </section>

        {/* Section: Contact Us */}
        <section id="contact-us" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Contact Us</h2>
          <p className="leading-relaxed text-gray-600">
            If you have questions about our refund policy or need assistance with a refund request, please contact our support team. We are here to help and ensure that your experience with our services meets your expectations.
          </p>
          <a href="mailto:support@example.com" className="text-blue-600 hover:underline">support@roamerz.com</a>
        </section>
      </div>
    </section>
  );
};

export default RefundPolicy;
