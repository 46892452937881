import React from 'react';
import addFundsMockup from '../assets/mockups/mockup5.png';
import qrCodeMockup from '../assets/mockups/mockup2.png';
import activationMockup from '../assets/mockups/mockup4.png';
import globalConnectMockup from '../assets/mockups/mockup1.png';

const HowItWorks = () => (
    <section id="how-it-works" className="px-4 sm:px-8 py-16 bg-white">
        <div className="container mx-auto text-center">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">How It Works</h2>
            <p className="text-gray-600 max-w-2xl mx-auto mb-12">
                Using our eSIM service is simple and convenient. Follow these steps to get connected and stay online wherever you go.
            </p>

            {/* Step 1: Add Funds */}
            <div className="flex flex-col md:flex-row items-center mb-12">
                <img src={addFundsMockup} alt="Add funds to your account" className="w-full md:w-1/2 mb-6 md:mb-0" />
                <div className="md:ml-8 text-left max-w-lg">
                    <h5 className="text-2xl font-semibold text-gray-800 mb-2">Add Funds to Your Account</h5>
                    <p className="text-gray-600">
                        Start by creating an account on our eSIM app. Once your account is set up, add funds to cover your anticipated data usage. Our app allows you to select a plan based on your needs, whether you're traveling for a week or staying abroad long-term.
                    </p>
                    <p className="text-gray-600 mt-4">
                        You can easily top up your balance as needed, giving you the flexibility to adjust based on how much data you use. Adding funds is quick and secure, and we offer multiple payment options to make the process as smooth as possible.
                    </p>
                </div>
            </div>

            {/* Step 2: Receive Your QR Code */}
            <div className="flex flex-col md:flex-row-reverse items-center mb-12">
                <img src={qrCodeMockup} alt="Receive your QR code" className="w-full md:w-1/2 mb-6 md:mb-0" />
                <div className="md:mr-8 text-left max-w-lg">
                    <h5 className="text-2xl font-semibold text-gray-800 mb-2">Receive Your QR Code</h5>
                    <p className="text-gray-600">
                        After adding funds, our app will generate a unique QR code for your eSIM. You’ll receive this code via email and in-app. The QR code is essential for activating your eSIM profile on your device.
                    </p>
                    <p className="text-gray-600 mt-4">
                        Simply follow the prompts in the app to view and save your QR code. The code remains available in your account for easy access, in case you need to re-scan it on a different device or at a later time.
                    </p>
                </div>
            </div>

            {/* Step 3: Activate Your eSIM */}
            <div className="flex flex-col md:flex-row items-center mb-12">
                <img src={activationMockup} alt="Activate your eSIM" className="w-full md:w-1/2 mb-6 md:mb-0" />
                <div className="md:ml-8 text-left max-w-lg">
                    <h5 className="text-2xl font-semibold text-gray-800 mb-2">Activate Your eSIM</h5>
                    <p className="text-gray-600">
                        With your QR code ready, go to your device’s settings to add a new cellular plan. Scan the QR code when prompted, and your eSIM will be automatically installed on your device. The process is quick and guided by prompts, making it easy to follow.
                    </p>
                    <p className="text-gray-600 mt-4">
                        Once activated, your eSIM profile will enable cellular connectivity in the specified region. You can switch between your eSIM and physical SIM directly from your device’s settings without needing to swap SIM cards physically.
                    </p>
                </div>
            </div>

            {/* Step 4: Stay Connected Worldwide */}
            <div className="flex flex-col md:flex-row-reverse items-center">
                <img src={globalConnectMockup} alt="Stay connected worldwide" className="w-full md:w-1/2 mb-6 md:mb-0" />
                <div className="md:mr-8 text-left max-w-lg">
                    <h5 className="text-2xl font-semibold text-gray-800 mb-2">Stay Connected Worldwide</h5>
                    <p className="text-gray-600">
                        Once your eSIM is activated, you’re ready to connect to high-speed networks in over 130+ countries. Whether you're in a bustling city or a remote location, our global network coverage ensures reliable internet access wherever you go.
                    </p>
                    <p className="text-gray-600 mt-4">
                        Our app provides usage tracking, plan updates, and easy top-ups, giving you complete control over your data consumption. With our eSIM, you’re always connected without the need for local SIM cards or dealing with multiple carriers.
                    </p>
                </div>
            </div>
        </div>
    </section>
);

export default HowItWorks;
