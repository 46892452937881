import React from 'react';

const PrivacyPolicy = () => {
  return (
    <section className="px-6 py-16 bg-gray-50 text-gray-800">
      <div className="container mx-auto max-w-4xl">
        <h1 className="text-4xl font-bold mb-6 text-center">Privacy Policy</h1>

        {/* Table of Contents */}
        <div className="bg-white shadow-md rounded-lg p-6 mb-12">
          <h2 className="text-2xl font-semibold mb-4">Table of Contents</h2>
          <ul className="list-disc pl-5 space-y-2 text-gray-700">
            <li><a href="#data-collection" className="hover:underline">Data Collection</a></li>
            <li><a href="#data-usage" className="hover:underline">How We Use Your Data</a></li>
            <li><a href="#data-sharing" className="hover:underline">Data Sharing and Disclosure</a></li>
            <li><a href="#data-security" className="hover:underline">Data Security</a></li>
            <li><a href="#user-rights" className="hover:underline">Your Rights</a></li>
            <li><a href="#cookies" className="hover:underline">Cookies and Tracking Technologies</a></li>
            <li><a href="#policy-changes" className="hover:underline">Changes to This Policy</a></li>
            <li><a href="#contact-us" className="hover:underline">Contact Us</a></li>
          </ul>
        </div>

        {/* Section: Data Collection */}
        <section id="data-collection" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Data Collection</h2>
          <p className="leading-relaxed text-gray-600">
            We collect information to provide and improve our services. The types of information we collect include personal information (such as name, email address, and payment information) and usage data (such as IP addresses and device information). 
          </p>
          <ul className="list-disc pl-5 mt-4 space-y-2 text-gray-600">
            <li><strong>Personal Information:</strong> When you create an account, make a purchase, or interact with our services, we may collect information like your name, email address, and payment details.</li>
            <li><strong>Usage Data:</strong> This includes data about how you use our services, such as IP addresses, browser type, operating system, and device identifiers.</li>
            <li><strong>Cookies:</strong> Cookies and similar tracking technologies are used to gather information about how you interact with our website.</li>
          </ul>
        </section>

        {/* Section: Data Usage */}
        <section id="data-usage" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">How We Use Your Data</h2>
          <p className="leading-relaxed text-gray-600">
            We use the data we collect to deliver and improve our services, process transactions, communicate with you, and ensure a secure experience. This may include using your information for:
          </p>
          <ul className="list-disc pl-5 mt-4 space-y-2 text-gray-600">
            <li><strong>Service Delivery:</strong> To process your orders, provide customer support, and deliver relevant content.</li>
            <li><strong>Personalization:</strong> To personalize your experience, including suggesting products and content based on your preferences.</li>
            <li><strong>Communication:</strong> To send important updates, such as service notifications, and to respond to inquiries.</li>
            <li><strong>Security:</strong> To protect against unauthorized access, fraud, and other security issues.</li>
          </ul>
        </section>

        {/* Section: Data Sharing */}
        <section id="data-sharing" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Data Sharing and Disclosure</h2>
          <p className="leading-relaxed text-gray-600">
            We do not sell your personal information. However, we may share your data with third-party service providers who assist in delivering our services, as well as in other circumstances where disclosure is legally required.
          </p>
          <ul className="list-disc pl-5 mt-4 space-y-2 text-gray-600">
            <li><strong>Service Providers:</strong> We may share your information with companies that provide services such as payment processing, customer support, and data analytics.</li>
            <li><strong>Legal Requirements:</strong> We may disclose your information to comply with legal obligations, such as responding to lawful requests by government authorities.</li>
            <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your data may be transferred as part of the transaction.</li>
          </ul>
        </section>

        {/* Section: Data Security */}
        <section id="data-security" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Data Security</h2>
          <p className="leading-relaxed text-gray-600">
            We prioritize the security of your personal information and implement various measures to protect it from unauthorized access, loss, or misuse. While we strive to use commercially acceptable means to protect your data, we cannot guarantee its absolute security.
          </p>
          <p className="leading-relaxed text-gray-600 mt-4">
            Security practices include encryption, access control, and regular audits to ensure data safety. However, it is important to understand that no online service is entirely risk-free.
          </p>
        </section>

        {/* Section: User Rights */}
        <section id="user-rights" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Your Rights</h2>
          <p className="leading-relaxed text-gray-600">
            You have certain rights regarding your personal data, including the right to access, update, or delete your information. If you wish to exercise any of these rights, please contact us, and we will address your request in accordance with applicable laws.
          </p>
          <ul className="list-disc pl-5 mt-4 space-y-2 text-gray-600">
            <li><strong>Access:</strong> You may request access to the personal information we hold about you.</li>
            <li><strong>Correction:</strong> If any information is inaccurate, you can request corrections or updates.</li>
            <li><strong>Deletion:</strong> You have the right to request the deletion of your data, subject to certain legal obligations.</li>
            <li><strong>Objections:</strong> You may object to the processing of your data for specific purposes, such as marketing.</li>
          </ul>
        </section>

        {/* Section: Cookies and Tracking */}
        <section id="cookies" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Cookies and Tracking Technologies</h2>
          <p className="leading-relaxed text-gray-600">
            Our website uses cookies and similar tracking technologies to enhance your experience, analyze site performance, and provide personalized content. You can manage cookie preferences through your browser settings.
          </p>
          <p className="leading-relaxed text-gray-600 mt-4">
            By continuing to use our website without changing your settings, you consent to our use of cookies in accordance with this policy.
          </p>
        </section>

        {/* Section: Changes to This Policy */}
        <section id="policy-changes" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Changes to This Policy</h2>
          <p className="leading-relaxed text-gray-600">
            We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. When we make significant updates, we will notify you via email or post a notice on our website.
          </p>
          <p className="leading-relaxed text-gray-600 mt-4">
            We encourage you to review this policy regularly to stay informed about how we are protecting your data.
          </p>
        </section>

        {/* Section: Contact Us */}
        <section id="contact-us" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Contact Us</h2>
          <p className="leading-relaxed text-gray-600">
            If you have questions about this Privacy Policy or need further information regarding our data practices, please contact our support team.
          </p>
          <a href="mailto:support@roamerz.com" className="text-blue-600 hover:underline">support@example.com</a>
        </section>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
