import React from 'react';
import { Card } from 'flowbite-react';
import { FaMoneyBillAlt, FaBolt, FaGlobe } from 'react-icons/fa';

const Features = () => (
    <section id="features" className="px-4 sm:px-8 py-20 bg-black">
        <div className="container mx-auto text-center">
            <h2 className="text-3xl text-white font-bold text-gray-800 mb-6">Why Choose Our eSIMs?</h2>
            <p className="text-gray-100 max-w-2xl mx-auto mb-12">
                Our eSIM solution provides a seamless, global connectivity experience. Here’s what makes us stand out.
            </p>

            <div className="py-8 grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Feature 1: 0 Hidden Fees */}
                <Card className="transform transition-transform hover:scale-105 hover:shadow-xl">
                    <div className="p-6 text-center">
                        <FaMoneyBillAlt className="text-orange-500 text-5xl mb-4 mx-auto" />
                        <h3 className="text-xl font-semibold mb-3">Zero Hidden Fees</h3>
                        <p className="text-gray-700">
                            Enjoy transparent pricing with no hidden charges. Only pay for the data you need, with no surprise costs.
                        </p>
                    </div>
                </Card>

                {/* Feature 2: Instant Activation */}
                <Card className="transform transition-transform hover:scale-105 hover:shadow-xl">
                    <div className="p-6 text-center">
                        <FaBolt className="text-green-600 text-5xl mb-4 mx-auto" />
                        <h3 className="text-xl font-semibold mb-3">Instant Activation</h3>
                        <p className="text-gray-700">
                            Connect instantly with our eSIM. Enjoy hassle-free setup and immediate access to high-speed internet.
                        </p>
                    </div>
                </Card>

                {/* Feature 3: One eSIM Everywhere */}
                <Card className="transform transition-transform hover:scale-105 hover:shadow-xl">
                    <div className="p-6 text-center">
                        <FaGlobe className="text-blue-600 text-5xl mb-4 mx-auto" />
                        <h3 className="text-xl font-semibold mb-3">One eSIM Everywhere</h3>
                        <p className="text-gray-700">
                            Use one eSIM across the globe with seamless connectivity. No need to switch SIM cards when you travel.
                        </p>
                    </div>
                </Card>
            </div>
        </div>
    </section>
);

export default Features;
