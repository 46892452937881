import { useState, useEffect } from "react"
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Link, animateScroll as scroll } from "react-scroll"
import { Navbar } from "flowbite-react";
import logo from "../logo.svg";

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 100);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <Navbar fluid className={`sticky top-0 z-20${isScrolled ? " shadow-md" : ""}`}>
            <Navbar.Brand as={RouterLink} to="/" onClick={() => scroll.scrollToTop()} className="cursor-pointer">
                <img src={logo} className="mr-3 h-6 sm:h-9" alt="Roamerz" />
                <span className="self-center whitespace-nowrap text-2xl font-semibold">Roamerz</span>
            </Navbar.Brand>
            {location.pathname === '/' && (
                <>
                    <Navbar.Toggle />
                    <Navbar.Collapse>
                        <Link smooth
                            to="features"
                            duration={1000}
                            spy={true}
                            activeClass="text-blue-600"
                            className="md:hover:text-blue-600 cursor-pointer"
                        >
                            Features
                        </Link>
                        <Link smooth
                            to="how-it-works"
                            duration={1000}
                            spy={true}
                            activeClass="text-blue-600"
                            className="md:hover:text-blue-600 cursor-pointer"
                        >
                            How It Works
                        </Link>
                        <Link smooth
                            to="faq"
                            duration={1000}
                            spy={true}
                            activeClass="text-blue-600"
                            className="md:hover:text-blue-600 cursor-pointer"
                        >
                            FAQ
                        </Link>
                    </Navbar.Collapse>
                </>
            )}
        </Navbar>
    )
};

export default Header;