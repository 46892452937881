import React, { useEffect, useState } from "react";
import appStoreButton from "../assets/icons/app-store-button.svg"
import playStoreButton from "../assets/icons/play-store-button.svg"
import MainMockup from "../assets/mockups/mockup3.png"

const Hero = () => {
    const [platform, setPlatform] = useState('browser');

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            setPlatform('android');
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setPlatform('ios');
        } else {
            setPlatform('browser');
        }
    }, []);

    return (
        <section id="hero" class="bg-white">
            <div class="grid pt-8 px-4 mx-auto max-w-screen-xl lg:gap-8 xl:gap-0 lg:pt-16 lg:grid-cols-12">
                <div class="place-self-center mr-auto lg:col-span-7">
                    <h1 class="mb-4 max-w-2xl text-4xl font-extrabold leading-none sm:text-5xl xl:text-5xl">Stay connected worldwide with affordable eSIMs</h1>
                    <p class="mb-6 max-w-2xl font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">Say goodbye to expensive roaming charges, physical SIM cards, and unreliable Wi-Fi. Experience seamless global connectivity in 130+ countries at affordable rates with Roamerz.</p>
                    <div className="flex max-sm:justify-center md:justify-left space-x-4 mt-6">
                        {(platform === 'browser' || platform === 'ios') && <a href="https://apps.apple.com/app" class="h-10" target="_blank" rel="noopener noreferrer">
                            <img src={appStoreButton} alt="get on App Store Icon" />
                        </a>}
                        {(platform === 'browser' || platform === 'android') && <a href="https://play.google.com/store" class="h-10" target="_blank" rel="noopener noreferrer">
                            <img src={playStoreButton} alt="get on Play Store Icon" />
                        </a>}
                    </div>
                </div>
                <div class="mt-10 lg:mt-0 lg:col-span-5 flex">
                    <img src={MainMockup} alt="mockup" />
                </div>
            </div>
            <div class="pt-6 pb-6 bg-white">
                <dl class="grid max-w-screen-xl grid-cols-1 gap-8 mx-auto text-gray-700 sm:grid-cols-1 md:grid-cols-4 xl:grid-cols-4 sm:p-8">
                    <div class="flex flex-col items-center justify-center">
                        <dt class="mb-2 text-3xl font-extrabold">130+</dt>
                        <dd class="text-gray-500">Countries</dd>
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <dt class="mb-2 text-3xl font-extrabold">99.9%</dt>
                        <dd class="text-gray-500">Network Uptime</dd>
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <dt class="mb-2 text-3xl font-extrabold">24/7</dt>
                        <dd class="text-gray-500">Customer Support</dd>
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <dt class="mb-2 text-3xl font-extrabold">100%</dt>
                        <dd class="text-gray-500">Digital</dd>
                    </div>
                </dl>
            </div>
        </section>
    )
};

export default Hero;