import { Footer } from "flowbite-react";
import logo from "../logo.svg"
import { BsFacebook, BsInstagram, BsTwitter, BsWhatsapp } from "react-icons/bs";
import { MdOutlineAlternateEmail } from "react-icons/md";

export default function Component() {
    return (
        <Footer container>
            <div className="w-full text-center">
                <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
                    <Footer.Brand
                        href="/"
                        src={logo}
                        alt="Roamerz"
                        name="Roamerz"
                    />
                    <Footer.LinkGroup>
                        <Footer.Link href="/privacy-policy">Privacy Policy</Footer.Link>
                        <Footer.Link href="/terms-of-service">Terms Of Service</Footer.Link>
                        <Footer.Link href="/refund-policy">Refunds Policy</Footer.Link>
                    </Footer.LinkGroup>
                </div>
                <Footer.Divider />
                <div className="w-full sm:flex sm:items-center sm:justify-between">
                    <Footer.Copyright href="#" by="Roamerz" year={new Date().getFullYear()} />
                    <div className="mt-4 flex space-x-6 sm:mt-0 justify-center">
                        <Footer.Icon href="#" icon={MdOutlineAlternateEmail} />
                        <Footer.Icon href="#" icon={BsWhatsapp} />
                        <Footer.Icon href="#" icon={BsFacebook} />
                        <Footer.Icon href="#" icon={BsInstagram} />
                        <Footer.Icon href="#" icon={BsTwitter} />
                    </div>
                </div>
            </div>
        </Footer>
    );
}
