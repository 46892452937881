import React from 'react';

const TermsOfService = () => {
  return (
    <section className="px-6 py-16 bg-gray-50 text-gray-800">
      <div className="container mx-auto max-w-4xl">
        <h1 className="text-4xl font-bold mb-6 text-center">Terms of Service</h1>
        
        {/* Table of Contents */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">Table of Contents</h2>
          <ul className="list-disc pl-5 space-y-2 text-gray-700">
            <li><a href="#introduction" className="hover:underline">Introduction</a></li>
            <li><a href="#user-responsibilities" className="hover:underline">User Responsibilities</a></li>
            <li><a href="#account-terms" className="hover:underline">Account Terms</a></li>
            <li><a href="#payment-and-fees" className="hover:underline">Payment and Fees</a></li>
            <li><a href="#service-limits" className="hover:underline">Service Limits</a></li>
            <li><a href="#termination" className="hover:underline">Termination</a></li>
            <li><a href="#contact-us" className="hover:underline">Contact Us</a></li>
          </ul>
        </div>

        {/* Section: Introduction */}
        <section id="introduction" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Introduction</h2>
          <p className="leading-relaxed text-gray-600">
            Welcome to our Terms of Service page. By using our services, you agree to comply with the following terms and conditions. Please read them carefully to understand your rights and responsibilities. These terms govern your access to and use of our platform, including any associated applications, websites, and services provided by us.
          </p>
          <p className="leading-relaxed text-gray-600 mt-4">
            These Terms of Service, alongside our Privacy Policy, form a binding agreement between you and [YourBrand]. Failure to comply with these terms may result in suspension or termination of access to our services.
          </p>
        </section>

        {/* Section: User Responsibilities */}
        <section id="user-responsibilities" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">User Responsibilities</h2>
          <p className="leading-relaxed text-gray-600">
            As a user of our platform, you agree to act responsibly and follow all applicable laws and guidelines. You are responsible for maintaining the confidentiality of your account information and are liable for all activities that occur under your account.
          </p>
          <ul className="list-disc pl-5 mt-4 space-y-2 text-gray-600">
            <li>Keep your account information secure, including your password and other access credentials.</li>
            <li>Use strong passwords and change them regularly to prevent unauthorized access.</li>
            <li>Do not share your account credentials with others or allow others to access your account on your behalf.</li>
            <li>Report any suspicious activity on your account immediately to our support team.</li>
          </ul>
          <p className="leading-relaxed text-gray-600 mt-4">
            Violation of these responsibilities may lead to account suspension or termination. We reserve the right to investigate and address any misuse of our platform to maintain a safe and fair environment for all users.
          </p>
        </section>

        {/* Section: Account Terms */}
        <section id="account-terms" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Account Terms</h2>
          <p className="leading-relaxed text-gray-600">
            When creating an account, you agree to provide accurate, complete, and up-to-date information as requested. You are solely responsible for ensuring that your information is accurate and for updating it as necessary. Failure to maintain accurate information may affect your access to services.
          </p>
          <p className="leading-relaxed text-gray-600 mt-4">
            Your account is personal and non-transferable. You may not sell, transfer, or share your account with others. You are responsible for all activities conducted through your account and for ensuring compliance with these terms.
          </p>
        </section>

        {/* Section: Payment and Fees */}
        <section id="payment-and-fees" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Payment and Fees</h2>
          <p className="leading-relaxed text-gray-600">
            All transactions conducted on our platform are subject to applicable fees. We are committed to transparency, and all fees associated with your chosen plan will be disclosed at the time of purchase. By completing a transaction, you authorize us to charge the applicable amount for your selected services.
          </p>
          <ul className="list-disc pl-5 mt-4 space-y-2 text-gray-600">
            <li>All fees are non-refundable unless explicitly stated otherwise in specific cases, such as service errors or billing mistakes.</li>
            <li>Additional charges may apply based on usage, upgrades, or additional features.</li>
            <li>Payments are securely processed through third-party providers who adhere to industry-standard security measures.</li>
          </ul>
          <p className="leading-relaxed text-gray-600 mt-4">
            You are responsible for reviewing and understanding any charges associated with our services. We reserve the right to change our pricing and fees at any time, and any changes will be communicated through the platform.
          </p>
        </section>

        {/* Section: Service Limits */}
        <section id="service-limits" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Service Limits</h2>
          <p className="leading-relaxed text-gray-600">
            Our services are subject to certain limits, including data usage, speed, and geographical coverage. These limits are set to ensure fair and reliable service for all users. While we strive to offer uninterrupted service, technical issues, network maintenance, or other unforeseen circumstances may occasionally disrupt service.
          </p>
          <p className="leading-relaxed text-gray-600 mt-4">
            We are not liable for service disruptions beyond our control, and we reserve the right to modify service limits as necessary to maintain platform quality and user satisfaction.
          </p>
        </section>

        {/* Section: Termination */}
        <section id="termination" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Termination</h2>
          <p className="leading-relaxed text-gray-600">
            We reserve the right to suspend or terminate your account if you violate any of these Terms of Service or engage in unauthorized or harmful activities. In such cases, you may be prohibited from accessing our services in the future.
          </p>
          <p className="leading-relaxed text-gray-600 mt-4">
            If you wish to terminate your account, you may contact our support team to request closure. Terminating your account does not relieve you of any outstanding obligations, such as payment of pending fees or compliance with applicable laws.
          </p>
        </section>

        {/* Section: Contact Us */}
        <section id="contact-us" className="pt-20">
          <h2 className="text-2xl font-semibold mb-3">Contact Us</h2>
          <p className="leading-relaxed text-gray-600">
            For any questions, concerns, or issues regarding these Terms of Service, please feel free to reach out to our support team. We are here to assist you and ensure a smooth experience on our platform.
          </p>
          <a href="mailto:support@getroamerz.com" className="text-blue-600 hover:underline">support@example.com</a>
        </section>
      </div>
    </section>
  );
};

export default TermsOfService;
