import React from "react";
import Hero from "../components/Hero";
import Features from "../components/Features";
import HowItWorks from "../components/HowItWorks";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";

const Main = () => (
    <div className="bg-gray-100 text-gray-800">
        <Hero />
        <Features />
        <HowItWorks />
        <FAQ />
        <Footer />
    </div>
);

export default Main;